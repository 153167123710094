<template>
  <v-container fluid>
    <!-- Teams card -->

    <!-- search and other options  -->
    <v-card flat class="transparent pl-2 pr-2">
      <v-card-title>
        <h3 class="font-weight-medium pl-3 pr-3">
          Members
        </h3>
        <v-spacer />
        <div v-if="canAccess" class="d-flex d-md-none">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="primarygrad rounded ma-1" v-bind="attrs" v-on="on">
                <v-icon>menu</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link to="/apps">
                <v-list-item-title>
                  <v-icon left color="primary">get_app</v-icon>Team App
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="addTeam">
                <v-list-item-title>
                  Add Member <v-icon right color="primary">add_circle</v-icon>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="selectedTeam != 'allTeams'"
                @click="importDialog = true"
              >
                <v-list-item-title>
                  <v-icon left color="primary">group_add</v-icon>Import
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="exportMembers">
                <v-list-item-title>
                  <v-icon left color="primary">get_app</v-icon>Export
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="batchDelete">
                <v-list-item-title>
                  <v-icon left color="primary">delete_sweep</v-icon>Remove
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card-title>

      <v-card-text
        v-if="canAccess"
        class="d-flex"
        data-intercom-target="membercontrol"
      >
        <v-text-field
          v-model="search"
          clearable
          solo-inverted
          flat
          dense
          :hide-details="true"
          class="py-1 rounded pl-3 pr-3"
          prepend-inner-icon="search"
          label="Search Members"
          @click:clear="clearSearch"
          @change="refresh"
        />

        <v-spacer class="d-none d-md-flex"></v-spacer>

        <div class="d-none d-md-flex pr-3">
          <v-chip label class="ma-1  rounded" link to="/apps">
            <v-icon left color="primary">get_app</v-icon>Team App
          </v-chip>

          <v-chip label class="ma-1  rounded" @click="addTeam">
            <v-icon left color="primary">add</v-icon>Add
          </v-chip>

          <v-chip
            v-if="selectedTeam != 'allTeams'"
            label
            class="ma-1  rounded"
            @click="importDialog = true"
          >
            <v-icon left color="primary">group_add</v-icon>Import
          </v-chip>

          <v-chip label class="ma-1  rounded" @click="exportMembers">
            <v-icon left color="primary">get_app</v-icon>Export
          </v-chip>

          <v-chip label class="ma-1  rounded" @click="batchDelete">
            <v-icon left color="primary">delete_sweep</v-icon>Remove
          </v-chip>
        </div>
      </v-card-text>
      <!-- Show users -->

      <v-card-text v-if="totalRecords == 0">
        <v-container fill-height fill-width class="justify-center">
          <div justify="center" align="center">
            <v-img :src="noAgent" width="200" />
            <h3 class="my-3">No Members available.</h3>
            <v-btn color="primarygrad" @click="addTeam">
              <v-icon left>add_circle</v-icon>Add Member
              <v-icon right color="primary">add_circle</v-icon>
            </v-btn>
          </div>
        </v-container>
      </v-card-text>

      <v-card-text v-else>
        <v-flex row class="pa-0 ma-0">
          <v-col
            v-for="(item, i) in allUsers"
            :key="i"
            cols="12"
            sm="6"
            md="4"
            class="pa-1 ma-0"
          >
            <v-card class="secondarygrad rounded ma-1 pa-1 fill-height">
              <v-card-title>
                <v-checkbox
                  class="pa-0 ma-0 mb-n4"
                  color="primary"
                  @change="setSelectIds($event, item)"
                />
                <h3
                  class="font-weight-medium secondary--text text--darken-3 dotted-gradient "
                  style="cursor: pointer;"
                  @click="openPerformance(item.id)"
                >
                  {{ item.name }}
                </h3>
              </v-card-title>

              <v-card-text class="pa-0 mb-5">
                <!-- <router-link
                  :to="{ path: `/performance?userid=${item.id}` }"
                  style="text-decoration: none"
                > -->
                <v-avatar
                  v-if="item"
                  size="50"
                  color="primarygrad"
                  class=""
                  style="position: absolute; right:20px; top:20px;cursor: pointer;"
                  @click="openPerformance(item.id)"
                >
                  <v-img v-if="item.picture" :src="getImage(item.picture)" />
                  <span
                    v-else
                    class=" headline secondary--text"
                    style="color: white!important;"
                  >
                    {{ item.name.substring(0, 1).toUpperCase() }}
                  </span>
                </v-avatar>

                <div class="pa-3 secondary--text text--lighten-3">
                  <v-list-item-subtitle
                    >Role : {{ item.role }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    Username : {{ item.username }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    item.email != "" ? `Email : ${item.email}` : newLine
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    item.phone && item.phone.length > 0
                      ? `Phone : ${item.phone}`
                      : newLine
                  }}</v-list-item-subtitle>
                </div>

                <p
                  class=" pr-1 secondary--text font-weight-medium px-3"
                  style="line-height:0.5rem"
                >
                  Teams
                </p>
                <div class="d-flex flex-wrap px-3">
                  <p
                    v-for="(team, j) in item.teamName"
                    :key="j"
                    class="secondary--text text--lighten-3"
                  >
                    <v-chip class="customChipEllipse">{{ team }}</v-chip>
                  </p>
                </div>
                <v-divider></v-divider>

                <p
                  v-if="item.listName.length > 0"
                  class=" pr-1 secondary--text font-weight-medium px-3"
                  style="line-height:0.5rem"
                >
                  Lists

                  <v-btn
                    x-small
                    fab
                    color="primarygrad"
                    style="float:right"
                    @click="openAssignMember(item.id)"
                  >
                    <v-icon style="color: white;">
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </p>

                <!-- <div
                  v-if="item.listName.length > 0"
                  class="d-flex flex-wrap px-2"
                >
                  <p
                    v-for="(list, k) in item.lists"
                    :key="k"
                    class="secondary--text text--lighten-3 pl-1"
                    style="line-height:0.5rem"
                  >
                    <v-chip
                      close
                      label
                      @click:close="deleteAssignment(list, item)"
                      >{{ list.name }}</v-chip
                    >

                  </p>
                </div> -->

                <div class="d-flex flex-wrap px-2">
                  <div
                    class="chip-container"
                    :class="{ expanded: expandedItems[item.id] }"
                  >
                    <p
                      v-for="(list, k) in item.lists"
                      :key="k"
                      class="secondary--text text--lighten-3 pl-1 chip-item"
                      :class="{
                        'chip-hidden': !expandedItems[item.id] && k >= 4,
                      }"
                    >
                      <v-chip
                        close
                        label
                        @click:close="deleteAssignment(list, item)"
                      >
                        {{ list.name }}
                      </v-chip>
                    </p>
                  </div>

                  <div
                    v-if="item.lists.length > 4"
                    class="d-flex justify-center align-center"
                    style="padding-left: 37%;padding-top: 25px;"
                  >
                    <v-btn text @click="toggleExpand(item.id)" color="#37cf37">
                      {{ expandedItems[item.id] ? "Show less" : "Load more" }}
                    </v-btn>
                  </div>
                  <!-- <div
                    v-if="item.lists.length > 4"
                    class="d-flex justify-center"
                  >
                    <v-btn small text @click="toggleExpand(item.id)">
                      {{ expandedItems[item.id] ? "Show less" : "Load more" }}
                    </v-btn>
                  </div> -->
                </div>
                <div v-if="item.listName.length == 0" class="px-3">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        fab
                        color="primarygrad"
                        @click="openAssignMember(item.id)"
                      >
                        <v-icon v-bind="attrs" v-on="on">
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Assign List to {{ item.name }}</span>
                  </v-tooltip>
                  <span
                    class="pl-3 font-weight-medium secondary--text text--lighten-3"
                    >This Member has no List assigned</span
                  >
                </div>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  v-if="canAccess"
                  text
                  color="primary"
                  @click="changePassword(item)"
                  >Change Password</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn v-if="canAccess" icon @click="deleteUser(item)">
                  <v-icon>delete</v-icon>
                </v-btn>
                <v-btn icon @click="editTeam(item)">
                  <v-icon>edit</v-icon>
                </v-btn>
                <v-btn icon :to="{ path: `/performance?userid=${item.id}` }">
                  <v-icon>mdi-launch</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-flex
            v-if="$store.getters.user.role.toLowerCase() == 'manager'"
            xs12
            sm12
            md4
            class="pl-2 pr-2 paddingCustomAddMember"
          >
            <v-card
              class="add_button customBorderRadius"
              @click="addTeam"
              style="height: 200px; display: flex; justify-content: center; align-items: center;"
            >
              <v-icon size="50" style="margin-right: 5px;color: #37cf37"
                >people</v-icon
              >
              Add Member
              <v-icon size="22" style="margin-right: 5px">add_circle</v-icon>
            </v-card>
          </v-flex>
        </v-flex>
      </v-card-text>

      <!-- Pagination -->

      <v-divider style="margin-bottom: 10px"></v-divider>
      <v-card-text>
        <div class="v-data-footer">
          <v-spacer></v-spacer>
          <div class="v-data-footer__select">
            Rows per page:
            <div
              class="v-input v-input--hide-details v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-select"
            >
              <v-select
                v-model="records"
                :items="recordsPerPage"
                style="margin: 5px"
                @change="pagination"
              ></v-select>
            </div>
          </div>
          <div class="v-data-footer__select">
            <div class="v-data-footer__icons-before">
              <v-btn
                :disabled="page == 1 ? true : false"
                color="primary"
                fab
                small
                text
                @click="prevSet"
              >
                <v-icon :size="24">arrow_left</v-icon>
              </v-btn>
            </div>
            <div class="v-data-footer__pagination">
              {{ `${page}/${pageCount} of ${totalRecords}` }}
            </div>
            <div class="v-data-footer__icons-after">
              <v-btn
                :disabled="page >= pageCount ? true : false"
                color="primary"
                fab
                small
                text
                @click="nextSet"
              >
                <v-icon :size="24">arrow_right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>

      <!-- <v-card-text>
        <v-spacer></v-spacer>
        <div class="v-data-footer" align="right">
          <div class="v-data-footer__select">
            Rows per page:
            <div
              class="v-input v-input--hide-details v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-select"
            >
              <v-select
                v-model="records"
                :items="recordsPerPage"
                style="margin: 5px"
                @change="pagination"
              ></v-select>
            </div>
          </div>
          <div class="v-data-footer__select">
            <div class="v-data-footer__icons-before">
              <v-btn
                :disabled="page == 1 ? true : false"
                color="primary"
                fab
                small
                text
                @click="prevSet"
              >
                <v-icon :size="24">arrow_left</v-icon>
              </v-btn>
            </div>
            <div class="v-data-footer__pagination">
              {{ `${page}/${pageCount} of ${totalRecords}` }}
            </div>
            <div class="v-data-footer__icons-after">
              <v-btn
                :disabled="page >= pageCount ? true : false"
                color="primary"
                fab
                small
                text
                @click="nextSet"
              >
                <v-icon :size="24">arrow_right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text> -->
    </v-card>

    <!-- <v-divider class="mt-5"></v-divider> -->

    <div class="pl-9 pr-9">
      <v-card
        v-if="
          $store.getters.user.role.toLowerCase() == 'manager' ||
            $store.getters.user.role.toLowerCase() == 'teammanager'
        "
        flat
        data-intercom-target="teamcontrol"
        class="customPaddingandRadius"
      >
        <v-card-title>
          <h3 class="font-weight-medium">
            Teams
          </h3>
          <v-spacer />
          <v-btn
            v-if="canAccess"
            depressed
            class="px-2 primarygrad"
            @click="
              $router.push({ path: '/manage', query: { topic: 'teams' } })
            "
          >
            <v-icon left>mdi-account-multiple</v-icon>
            MANAGE
          </v-btn>
          <!-- <v-row style="margin: 0px">

        <v-flex xs8 sm8 md8>
          <h3
            style="
              margin-top: 8px;
              letter-spacing: 0.5px;
              opacity: 0.6;
              color: #1a1a1a;
              font-size: 17px;
            "
          >
            Teams
          </h3>
        </v-flex>
        <v-flex
          v-if="canAccess"
          xs4
          sm4
          md4
          style="text-align: right !important"
        >
          <v-btn
            color="primary"
            text
            tile
            style="text-transform: none; font-weight: 400; font-size: 14px"
            @click="
              $router.push({
                path: '/manage',
                query: {
                  topic: 'teams',
                },
              })
            "
          >
            Manage
            <v-icon size="22" style="margin-top: 3px">arrow_right</v-icon>
          </v-btn>
        </v-flex>
      </v-row> -->
        </v-card-title>

        <v-card-text v-if="teams.length > 0">
          <span>
            <v-chip
              v-for="(item, index) in teams.filter((t) => {
                return t.id != 'allTeams';
              })"
              :key="index"
              label
              class="ma-1 px-0  rounded"
            >
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn text style="text-transform:none !important" v-on="on">
                    <v-avatar left color="primarygrad">
                      {{ item.name.substring(0, 1).toUpperCase() }}
                    </v-avatar>
                    {{ item.name }}
                    <v-spacer></v-spacer>
                    <v-icon right>expand_more</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="editItem(item)">
                    <v-icon small left color="primary">edit</v-icon>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="$store.getters.user.role == 'Manager'"
                    @click="delItem(item)"
                  >
                    <v-icon small left color="primary">delete</v-icon>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-chip>
          </span>

          <v-btn
            v-if="$store.getters.user.role.toLowerCase() == 'manager'"
            text
            class="primary--text"
            @click="openDialog"
          >
            <v-icon left color="primary">add</v-icon>Add Team
          </v-btn>
        </v-card-text>

        <v-card-text v-else style="padding: 20px">
          <v-chip
            v-if="$store.getters.user.role.toLowerCase() == 'manager'"
            style="margin: 7px"
            @click="openDialog"
          >
            <v-icon left color="primary">add</v-icon>Add Team
          </v-chip>

          <span>Nothing in Teams</span>
        </v-card-text>
      </v-card>
    </div>

    <!-- add member -->
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="primarygrad">
          {{ formTitle }} Member
          <v-spacer></v-spacer>
          <v-icon @click="close">mdi-close-circle</v-icon>
        </v-card-title>

        <v-card-text class="row" style="margin: 0px">
          <v-flex
            v-if="!newMember"
            xs12
            sm12
            md12
            style="
          
              text-align: center !important;
            "
          >
            <v-hover v-slot="{ hover }">
              <div align="center">
                <v-card
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  style="margin:15px;height:128px;max-height:128px;width:128px;max-width:128px; border-radius:50%"
                >
                  <v-img
                    :src="accountImg()"
                    style="height:128px;max-height:128px;width:128px;max-width:128px"
                    contain
                  >
                    <v-card-title class="title ">
                      <v-row
                        class="fill-height flex-column"
                        justify="space-between"
                        style="margin-top:75px"
                      >
                        <div class="align-self-center">
                          <v-btn
                            v-for="(icon, index) in icons"
                            :key="index"
                            :class="{ 'show-btns': hover }"
                            :color="transparent"
                            icon
                            @click="buttonOption(icon.value)"
                          >
                            <v-icon
                              :class="{ 'show-btns': hover }"
                              :color="transparent"
                            >
                              {{ icon.name }}
                            </v-icon>
                          </v-btn>
                        </div>
                      </v-row>
                    </v-card-title>
                  </v-img>
                </v-card>
              </div>
            </v-hover>
            <!-- <v-avatar
              color="primary"
              size="83"
              style="margin-top:29px"
              @click="$refs.file.$refs.input.click()"
            >
              <v-img v-if="logourl" :src="logourl" alt=""></v-img>
              <v-icon v-else>mdi-account</v-icon>
            </v-avatar> -->
            <!-- <img
              :src="logourl"
              alt=""
              style="margin-bottom: 25px;border-radius: 50%;"
            /> -->
            <v-file-input
              ref="file"
              v-model="uploadedLogo"
              style="display: none"
              @change="onFileSelect($event)"
            ></v-file-input>
            <!-- <input
                      ref="file"
                      type="file"
                      style="display: none"
                      @change="uploadFile($event)"
                    /> -->

            <!-- <button>Upload</button>  -->

            <!-- <div>
                      <span
                        ><v-btn
                          color="primary"
                          outlined
                          text
                          @click="$refs.file.$refs.input.click()"
                          >Upload</v-btn
                        >
                      </span>
                      <span style="padding-left: 2%"
                        ><v-btn
                          color="error"
                          outlined
                          text
                          @click="removeImage()"
                          >Remove</v-btn
                        ></span
                      >
                    </div> -->
          </v-flex>
          <v-flex xs12 sm12 md12 style="padding: 0px 5px">
            <v-text-field
              v-model="editedItem.name"
              label="Name *"
              required
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md12 style="padding: 0px 5px">
            <v-text-field
              v-model="editedItem.email"
              label="Email "
              :rules="rulesEmail"
              :disabled="this.editedIndex > -1"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md12 style="padding: 0px 5px">
            <v-text-field
              :value="editedItem.userid.toLowerCase()"
              label="User Id *"
              :rules="rulesUsername"
              required
              :disabled="disableUserID"
              @input="editedItem.userid = $event.toLowerCase()"
            ></v-text-field>
          </v-flex>

          <v-flex
            v-if="formTitle == 'New'"
            xs12
            sm12
            md12
            style="padding: 0px 5px"
          >
            <v-row>
              <v-col style="margin:auto">
                Set Password:
              </v-col>
              <v-col
                style=" display: flex;
  justify-content: center;"
              >
                <v-switch
                  v-model="setPasswordSwitch"
                  :label="
                    `${setPasswordSwitch.toString() == 'true' ? 'Yes' : 'No'}`
                  "
                ></v-switch>
              </v-col>
            </v-row>
            <v-text-field
              v-if="setPasswordSwitch"
              v-model="editedItem.password"
              label="Password *"
              :append-icon="visible ? 'visibility' : 'visibility_off'"
              :type="visible ? 'text' : 'password'"
              :rules="passwordRules"
              required
              @click:append="() => (visible = !visible)"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md12 style="padding: 0px 5px">
            <v-select
              v-if="editedItem.role != 'Manager'"
              v-model="editedItem.role"
              :value="editedItem.role"
              :disabled="allowRoleSelect()"
              :items="calcRole"
              item-text="text"
              item-value="value"
              class="mb-2"
              label="Role *"
              :hint="roleHint"
              persistent-hint
            ></v-select>
            <v-select
              v-else
              disabled
              value="Manager"
              :items="['Manager']"
              label="Role *"
              chips
            ></v-select>
          </v-flex>

          <v-flex xs12 sm12 md12 style="padding: 0px 5px">
            <v-autocomplete
              v-if="editedItem.role != 'Teammanager'"
              v-model="teamsId"
              :value="teamsId[0]"
              :items="
                teams.filter((t) => {
                  return t.id != 'allTeams';
                })
              "
              item-text="name"
              item-value="id"
              label="Select Team*"
            ></v-autocomplete>

            <!-- for team manager -->
            <v-autocomplete
              v-else
              v-model="teamsIds"
              :value="teamsId[0]"
              :items="
                teams.filter((t) => {
                  return t.id != 'allTeams';
                })
              "
              item-text="name"
              item-value="id"
              label="Select Teams*"
              chips
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption"
                  >(+{{ teamsIds.length - 1 }} others)</span
                >
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex xs12 sm12 md12 style="padding: 7px 5px">
            <vue-tel-input
              v-model="editedItem.phone"
              :valid-characters-only="true"
              class="secondary--text"
              :default-country="defaultCountry"
              @input="getPhoneNumber"
            ></vue-tel-input>
          </v-flex>
          <p class="text-caption-small">
            * GoDial only uses this phone number for message template
            generation. The username is used as the unique identifier for each
            member.
          </p>
          <v-flex xs12 sm12 md12 style="padding: 0px 5px">
            <v-list>
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Permissions</v-list-item-title>
                  </v-list-item-content>
                </template>
                <!-- CRM Access -->
                <v-list-item v-if="editedItem.role != 'Manager'">
                  <v-list-item-title>
                    Access CRM on App
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          style="opacity: 0.7"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          info_outline
                        </v-icon>
                      </template>
                      <span
                        >Permit the member to access and modify contacts on the
                        app</span
                      >
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-switch
                      v-model="editedItem.permissions.crm"
                      color="primary"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>
                <!-- Modify Member -->
                <v-list-item
                  v-if="
                    editedItem.role == 'Submanager' ||
                      editedItem.role == 'Teammanager'
                  "
                >
                  <v-list-item-title
                    >Modify Member
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          style="opacity: 0.7"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          info_outline
                        </v-icon>
                      </template>
                      <span>Permit the member to modify other members</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-switch
                      v-model="editedItem.permissions.modifyUser"
                      color="primary"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>
                <!-- Skip Call -->
                <v-list-item v-if="editedItem.role != 'Manager'">
                  <v-list-item-title
                    >Skip Call

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          style="opacity: 0.7"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          info_outline
                        </v-icon>
                      </template>
                      <span>Permit the caller to skip to the next call</span>
                    </v-tooltip>
                  </v-list-item-title>

                  <v-list-item-action>
                    <v-switch
                      v-model="editedItem.permissions.canSkipCall"
                      color="primary"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <!-- Call Recording -->
                <v-list-item>
                  <v-list-item-title
                    >Call Recording

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-badge
                          color="primary"
                          style="padding-left:5px;padding-top: -2px;"
                          content="BETA"
                        ></v-badge>
                        <v-icon
                          color="primary"
                          style="opacity: 0.7"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          info_outline
                        </v-icon>
                      </template>
                      <span>Permit the member to upload recorded calls</span>
                    </v-tooltip>
                  </v-list-item-title>

                  <v-list-item-action>
                    <v-switch
                      v-model="editedItem.permissions.recordCallsWithTrueCaller"
                      color="primary"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title
                    >Enable Whatsapp

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          style="opacity: 0.7"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          info_outline
                        </v-icon>
                      </template>
                      <span>Permit the member to access whatsapp</span>
                    </v-tooltip>
                  </v-list-item-title>

                  <v-list-item-action>
                    <v-switch
                      v-model="editedItem.permissions.whatsapp"
                      color="primary"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>
                <!-- Access My Contacts (Agents) -->
                <v-list-item v-if="editedItem.role == 'Agent'">
                  <v-list-item-title
                    >Show Only My Contacts

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          style="opacity: 0.7"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          info_outline
                        </v-icon>
                      </template>
                      <span>Only allow to see his own contacts in the app</span>
                    </v-tooltip>
                  </v-list-item-title>

                  <v-list-item-action>
                    <v-switch
                      v-model="editedItem.permissions.canAccessOnlyMyContacts"
                      color="primary"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-flex>

          <v-flex xs12 sm12 md12 style="padding: 0px 5px">
            <v-list>
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Security</v-list-item-title>
                  </v-list-item-content>
                </template>

                <!-- Allow All List -->
                <v-list-item
                  v-if="
                    editedItem.role == 'Submanager' ||
                      editedItem.role == 'Teammanager'
                  "
                >
                  <v-list-item-title
                    >Allow All List Access

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          style="opacity: 0.7"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          info_outline
                        </v-icon>
                      </template>
                      <span
                        >When enabled this user can access all lists in their
                        team, even without assignment</span
                      >
                    </v-tooltip>
                  </v-list-item-title>

                  <v-list-item-action>
                    <v-switch
                      v-model="editedItem.permissions.canAccessList"
                      color="primary"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <!-- Allow access Call Log -->
                <v-list-item>
                  <v-list-item-title>
                    Call Log access
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          style="opacity: 0.7"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          info_outline
                        </v-icon>
                      </template>
                      <span>Permit the member to access mobile call logs</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-switch
                      v-model="editedItem.permissions.canAccessCallLog"
                      color="primary"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-flex>

          <v-flex xs12 sm12 md12 style="padding: 0px 5px">
            <v-list>
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Attendance</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item>
                  <v-list-item-title
                    >Mark Attendance

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          style="opacity: 0.7"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          info_outline
                        </v-icon>
                      </template>
                      <span>Make checkin and checkout</span>
                    </v-tooltip>
                  </v-list-item-title>

                  <v-list-item-action>
                    <v-switch
                      v-model="editedItem.permissions.canMakeAttendance"
                      color="primary"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title
                    >Capture Location

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          style="opacity: 0.7"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          info_outline
                        </v-icon>
                      </template>
                      <span
                        >Enable the checkin and checkout location
                        capturing</span
                      >
                    </v-tooltip>
                  </v-list-item-title>

                  <v-list-item-action>
                    <v-switch
                      v-model="editedItem.permissions.canCaptureLocation"
                      color="primary"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title
                    >Capture Photo

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          style="opacity: 0.7"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          info_outline
                        </v-icon>
                      </template>
                      <span
                        >Enable the checkin and checkout photo capturing</span
                      >
                    </v-tooltip>
                  </v-list-item-title>

                  <v-list-item-action>
                    <v-switch
                      v-model="editedItem.permissions.canCapturePhoto"
                      color="primary"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <!-- Allow Session Lock -->

                <v-list-item>
                  <v-list-item-title>
                    Enable Session Lock
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          style="opacity: 0.7"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          info_outline
                        </v-icon>
                      </template>
                      <span
                        >Permit the member to access godial on specific
                        time</span
                      >
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-switch
                      v-model="editedItem.permissions.enableSessionLock"
                      color="primary"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-layout
                  v-if="
                    editedItem.permissions.enableSessionLock == true &&
                      editedItem.permissions.enableSessionLock != 'false'
                  "
                  row
                  wrap
                  style="margin: 0px;padding-top: 20px;"
                >
                  <v-flex xs12 sm6 md6 style="padding: 7px 5px">
                    <v-dialog
                      ref="menuShiftStart"
                      v-model="menuShiftStart"
                      :return-value.sync="editedItem.sessionStart"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.sessionStart"
                          label="Shift Start Time"
                          placeholder="10:00"
                          dense
                          outlined
                          hide-details="true"
                          readonly
                          class="mb-5 mr-2"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menuShiftStart"
                        v-model="editedItem.sessionStart"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menuShiftStart = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.menuShiftStart.save(editedItem.sessionStart)
                          "
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-flex>

                  <v-flex xs12 sm6 md6 style="padding: 7px 5px">
                    <v-dialog
                      ref="menuShiftEnd"
                      v-model="menuShiftEnd"
                      :return-value.sync="editedItem.sessionEnd"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.sessionEnd"
                          label="Shift End Time"
                          placeholder="10:00"
                          dense
                          outlined
                          hide-details="true"
                          readonly
                          class="mb-5 mr-2"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menuShiftEnd"
                        v-model="editedItem.sessionEnd"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menuShiftEnd = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.menuShiftEnd.save(editedItem.sessionEnd)
                          "
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-list-group>
            </v-list>
          </v-flex>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-if="!isMobile()">
          <v-btn text color="primary" @click="close">Cancel</v-btn>
          <v-btn v-if="canAccess" color="primarygrad  rounded" @click="save"
            >Save</v-btn
          >
        </v-card-actions>

        <v-card-actions v-if="isMobile()">
          <v-container>
            <v-row dense align="center" justify="center">
              <v-col cols="6" class="d-flex align-center justify-center">
                <v-btn
                  v-if="canAccess"
                  color="primarygrad  rounded"
                  @click="save"
                  >Save</v-btn
                >
              </v-col>
              <v-col cols="6" class="d-flex align-center justify-center">
                <v-btn text color="primary" @click="close">Cancel</v-btn>
              </v-col>
              <v-col cols="12" class="d-flex align-center justify-center">
                <v-btn
                  v-if="formTitle != 'New' && canAccess"
                  text
                  color="primary"
                  @click="changePassword(editedItem)"
                  >Change Password</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- end of user addition dialog -->
    <v-dialog v-model="cropDialog" width="500">
      <v-card style="padding:2%">
        <v-card-text style="margin:2%">
          <VueCropper
            v-show="selectedFile"
            ref="cropper"
            :src="selectedFile"
            :aspect-ratio="1"
            :zoomable="false"
            :scalable="false"
            alt="Source Image"
          ></VueCropper>
        </v-card-text>
        <v-card-actions>
          <v-btn class="primary" @click="saveImage(), (cropDialog = false)"
            >Crop</v-btn
          >
          <v-btn color="primary" text @click="cropDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- import contacts -->
    <v-dialog v-model="importDialog" hide-overlay persistent width="400">
      <v-card>
        <v-card-title class="primarygrad ">
          Import
          <v-spacer></v-spacer>
          <v-icon @click="importDialog = false">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text style="padding: 15px">
          <v-flex xs12 sm12 md12>
            <v-row style="margin:0px !important;">
              <v-flex xs10 sm10 md10>
                <v-file-input
                  append-icon="attach_file"
                  prepend-icon
                  label="Choose File"
                  outlined
                  dense
                  @change="upload"
                ></v-file-input>
              </v-flex>
              <v-flex
                xs2
                sm2
                md2
                style="margin-top:5px; text-align: center !important;"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" v-bind="attrs" v-on="on">
                      info_outline
                    </v-icon>
                  </template>
                  <span>Choose a xls or csv file with user data</span>
                </v-tooltip>
              </v-flex>
            </v-row>
          </v-flex>

          <v-flex xs12 sm12 md12>
            <h3
              style="
                font-size: 15px;
                font-weight: 500;
                opacity: 0.7;
                letter-spacing: 0.5px;
              "
            >
              Choose Columns To Import
            </h3>
          </v-flex>

          <v-flex xs12 sm12 md12>
            <v-checkbox
              v-model="containsTeam"
              color="primary"
              :label="`File has the name of the Team`"
            ></v-checkbox>
          </v-flex>

          <v-flex v-if="containsTeam" xs12 sm12 md12>
            <v-select
              v-model="importedItem.team"
              :items="headerList"
              label="In which column"
              outlined
              dense
            ></v-select>
          </v-flex>

          <v-divider style="margin-bottom: 10px"></v-divider>

          <v-flex xs12 sm12 md12>
            <v-select
              v-model="importedItem.name"
              :items="headerList"
              label="Select Name"
              outlined
              dense
            ></v-select>
          </v-flex>

          <v-flex xs12 sm12 md12>
            <v-select
              v-model="importedItem.phone"
              :items="headerList"
              label="Select Phone"
              outlined
              dense
            ></v-select>
          </v-flex>

          <v-flex xs12 sm12 md12>
            <v-select
              v-model="importedItem.email"
              :items="headerList"
              label="Select Email"
              outlined
              dense
            ></v-select>
          </v-flex>

          <v-flex xs12 sm12 md12>
            <v-select
              v-model="importedItem.username"
              :items="headerList"
              label="Select User Name"
              outlined
              dense
            ></v-select>
          </v-flex>

          <v-flex xs12 sm12 md12>
            <v-select
              v-model="importedItem.password"
              :items="headerList"
              label="Select Password"
              outlined
              dense
            ></v-select>
          </v-flex>

          <v-flex xs12 sm12 md12>
            <v-select
              v-model="importedItem.crm"
              :items="headerList"
              label="Access CRM on App"
              outlined
              dense
            ></v-select>
          </v-flex>

          <v-flex xs12 sm12 md12>
            <v-select
              v-model="importedItem.modifyUser"
              :items="headerList"
              label="Modify Member"
              outlined
              dense
            ></v-select>
          </v-flex>

          <v-flex xs12 sm12 md12>
            <v-select
              v-model="importedItem.canSkipCall"
              :items="headerList"
              label="Allow Skip Call"
              outlined
              dense
            ></v-select>
          </v-flex>

          <!-- <v-flex xs12 sm12 md12>
            <v-select
              v-model="importedItem.modifyContact"
              :items="headerList"
              label="Modify Contact"
              outlined
              dense
            ></v-select>
          </v-flex> -->

          <v-flex xs12 sm12 md12>
            <v-select
              v-model="importedItem.role"
              :items="headerList"
              label="Select Role"
              outlined
              dense
            ></v-select>
          </v-flex>
          <!-- <v-flex xs12 sm12 md12>
            <v-select
              v-model="importedItem.dTarget"
              :items="headerList"
              label="Select Daily Target"
              outlined
              dense
            ></v-select>
          </v-flex>
          <v-flex xs12 sm12 md12>
            <v-select
              v-model="importedItem.mTarget"
              :items="headerList"
              label="Select Monthly Target"
              outlined
              dense
            ></v-select>
          </v-flex> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="importDialog = false"
            >Cancel</v-btn
          >
          <v-btn class="primarygrad  rounded" @click="importAgents">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primarygrad ">
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogResetPassword" persistent width="500">
      <v-card>
        <v-card-title class="primarygrad ">
          <h4 style="font-size: 18px; font-weight: 500; letter-spacing: 0.5px">
            Set Password
          </h4>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="padding: 20px">
          <v-form>
            <v-text-field
              ref="resetPassword"
              v-model="newPassword"
              type="password"
              label="Enter New Password"
              required
              dense
              outlined
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialogResetPassword = false"
            >Cancel</v-btn
          >
          <v-btn
            class="primarygrad  rounded"
            outlined
            text
            @click="resetPassword"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- result dialog -->
    <v-dialog v-model="resultDialog" persistent scrollable max-width="500">
      <v-card>
        <v-card-title class=" primarygrad">Errors</v-card-title>
        <v-card-text style="height: 400px">
          <v-list two-line>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, index) in response" :key="index">
                <v-list-item-content>
                  <v-list-item-title>{{ index }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.msg }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primarygrad rounded" @click="resultDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- team dialog -->
    <v-dialog v-model="addTeamDialog" persistent width="400">
      <v-card>
        <v-card-title class="primarygrad ">{{ formTitle }}</v-card-title>
        <v-card-text style="padding: 20px">
          <v-row style="margin: 0px">
            <v-flex xs12 sm12 md12>
              <v-text-field
                v-model="teamItem.name"
                label="Name*"
              ></v-text-field>
            </v-flex>

            <!-- <v-flex xs12 sm12 md12>
              <v-textarea
                v-model="teamItem.desc"
                label="Description"
              ></v-textarea>
            </v-flex> -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="addTeamDialog = false">close</v-btn>
          <v-btn
            v-if="formTitle == 'New'"
            depressed
            class="primarygrad "
            @click="saveTeam"
            >Create</v-btn
          >
          <v-btn
            v-if="formTitle == 'Edit'"
            depressed
            class="primarygrad "
            @click="saveTeam"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <assignMember
      ref="assignMember"
      :lists="lists"
      :all-users="allUsers"
      @closeData="close"
      @refreshAll="refresh"
    >
    </assignMember>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import assignMember from "../components/assignMember.vue";

import compress from "compress-base64";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

const _ = require("lodash");
import Papa from "papaparse";
import Blob from "blob";
import FileSaver from "file-saver";
import validator from "../js/validator.js";
import * as XLSX from "xlsx/xlsx.mjs";

import fb from "../js/firebase";
import countries from "../js/countries";

export default {
  components: {
    assignMember,
    VueCropper,
  },
  data() {
    return {
      expandedItems: {},
      showAll: false,
      menuShiftStart: false,
      menuShiftEnd: false,
      containsTeam: false,
      setPasswordSwitch: false,
      editedIndex: -1,
      visible: "",
      headerList: [],
      importedData: [],
      menu_btn: false,
      importDialog: false,
      lists: [],
      selectedList: "",
      importedItem: {
        name: "",
        email: "",
        phone: "",
        role: "",
        //dTarget: 0,
        //mTarget: 0,
        username: "",
        password: "",
        team: "",
        crm: "",
        modifyUser: "",
        canSkipCall: "",
        // modifyContact: "",
      },
      teamItem: {
        name: "",
        desc: "",
      },
      addTeamDialog: false,
      dialogResetPassword: false,
      resetuserData: "",
      newPassword: "",
      selectedIds: [],
      newLine: "\u2800",
      resultDialog: false,
      headers: [
        { text: "Name", align: "left", sortable: true, value: "name" },
        {
          text: "User Name",
          align: "left",
          sortable: false,
          value: "username",
        },
        { text: "Phone", align: "left", sortable: false, value: "phone" },
        { text: "Email", align: "left", sortable: false, value: "email" },
        { text: "Role", align: "left", sortable: true, value: "role" },
        {
          text: "Access CRM on App",
          align: "left",
          sortable: false,
          value: "permissions.crm",
        },
        {
          text: "Assign List",
          align: "left",
          sortable: true,
          value: "listName",
        },
        {
          text: "Teams",
          align: "left",
          sortable: true,
          value: "teamName",
        },
        { text: "Edit", align: "center", sortable: false, value: "action" },
      ],
      roleType: [
        { text: "Team Manager", value: "Teammanager" },
        { text: "Sub Manager", value: "Submanager", selected: true },
        { text: "Agent", value: "Agent" },
      ],
      rulesEmail: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(v) ||
          "E-mail must be valid",
      ],
      rulesUsername: [
        (v) =>
          /^\w+(\.\w+)?$/.test(v) ||
          "Userid should be Lowercase & Must contains only letters,numbers and dot",
      ],
      passwordRules: [
        (v) =>
          /^(?!.* )(?=.*[a-zA-Z]).(?=.*[0-9]).{7,}$/.test(v) ||
          "Minimum eight characters, at least one letter and one number.",
      ],
      //rulesTarget: (v) => v >= 0 || "Cannot be negative ",
      // allUsers: [],
      preloader: false,
      response: {},
      loader: false,
      search: "",
      dialog: false,
      editedItem: {
        name: "",
        email: "",
        phone: "",
        password: "",
        userid: "",
        role: "",
        sessionStart: null,
        sessionEnd: null,
        permissions: {
          crm: false,
          modifyUser: false,
          canSkipCall: false,
          canAccessList: true,
          recordCallsWithTrueCaller: false,
          canAccessOnlyMyContacts: false,
          canAccessCallLog: false,
          whatsapp: true,
          enableSessionLock: false,
          canMakeAttendance: false,
          location: false,
          photo: false,
        },
        teamsId: [],
        //dTarget: 0,
        //mTarget: 0,
      },
      teamsId: "",
      teamsIds: [],
      resStatus: {
        icon: "",
        type: "",
        text: "",
      },
      orgName: "",
      reset: {
        old: "",
        new: "",
        confirmNew: "",
      },
      allUsers: [],
      loading: false,
      loadingText: "",
      // Pagination
      recordsPerPage: [6, 12, 36, 60, 120],
      page: 1,
      pageCount: 0,
      totalRecords: 0,
      records: 6,
      limit: 6,
      skip: 0,
      uploadedLogo: null,
      logourl: "",
      logoId: "",
      cropDialog: false,
      selectedFile: null,
      fileName: "",
      icons: [
        {
          name: "mdi-pencil",
          value: "edit",
        },
        {
          name: "mdi-delete",
          value: "delete",
        },
      ],
      transparent: "rgba(255, 255, 255, 0)",
      newMember: false,
      defaultCountry: "IN",
    };
  },
  computed: {
    ...mapState(["ENDPOINT", "user"]),
    selectedTeam: {
      get(v) {
        return this.$store.getters.selectedTeam;
      },
      set(v) {
        this.$store.commit("setSelectedTeam", v);
      },
    },
    roleHint() {
      if (this.editedItem.role == "Submanager") {
        return "Sub Managers can access Web Dashboard, and can make any change within their own team.";
      } else if (this.editedItem.role == "Teammanager") {
        return "Team Managers can access Web Dashboard, and can make any change in multiple teams.";
      } else if (this.editedItem.role == "Agent") {
        return "Agents can only access Team app for Dialing.";
      } else {
        return "";
      }
    },
    noAgent() {
      return require("../assets/noAgent.png");
    },
    teams() {
      return this.$store.getters.teams;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    disableUserID() {
      // If in edit more
      if (this.editedIndex > -1) {
        return true;
      } else {
        return false;
      }
    },
    // Permiisons settings
    canAccess() {
      var user = this.$store.getters.user;
      if (user) {
        // check if manager
        if (user.role.toLowerCase() == "manager") {
          return true;
        }
        // check if submanager and teammanager
        if (
          user.role.toLowerCase() == "submanager" ||
          user.role.toLowerCase() == "teammanager"
        ) {
          // check for permissions
          if (user.permissions && user.permissions.modifyUser) {
            return true;
          }
          return false;
        }
        return false;
      }
      return false;
    },
    calcRole() {
      var roles = [];

      if (this.$store.getters.user.role == "Submanager") {
        _.each(this.roleType, (r) => {
          if (r.value == "Teammanager") {
            r.disabled = true;
          }
          return roles.push(r);
        });
      } else {
        roles = this.roleType;
      }
      return roles;
    },

    /*rulesMonthlyTarget()
      {
        return ( this.editedItem.dTarget <= this.editedItem.mTarget || "Should not be less then Daily Target")
      }*/
  },
  watch: {
    selectedTeam() {
      this.refresh();
    },
  },
  async created() {
    const self = this;
    await self.fetchLists();
    await self.refresh();

    this.defaultCountry = this.getCountryCode(
      this.$store.state.companyDetails.country
    ); // Set default country name here
    // query
    if (self.$router.currentRoute.query) {
      if (self.$router.currentRoute.query.dashboardReq) self.addTeam();
      // open dialog for creating member
      else if (self.$router.currentRoute.query.tp) self.openDialog();

      if (self.$router.currentRoute.query.memberAdd) self.dialog = true;
    }
  },

  methods: {
    toggleExpand(userId) {
      this.$set(this.expandedItems, userId, !this.expandedItems[userId]);
    },
    getCountryCode(countryName) {
      const country = countries.find((item) => item.name === countryName);
      return country ? country.code : "IN";
    },
    deleteAssignment(list, user) {
      const self = this;
      // confirm deletion
      self.loader = true;
      self
        .$swal({
          type: "warning",
          title: `Are you sure?`,
          text: `${user.name} will be removed from assignment?`,
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            self.$http
              .post(
                `${self.ENDPOINT}/listsAccountThroughs/remove-list-account-through`,
                {
                  listsId: list.id,
                  accountIds: [user.id],
                }
              )
              .then((response) => {
                self.loader = false;
                self.$swal({ type: "success", text: "Successful" });
                self.close();
                self.refresh();
              })
              .catch((err) => {
                self.loader = false;
                console.log("Error", err);
                self.$swal({ type: "error", text: "Something went wrong" });
              });
          } else {
            self.loader = false;
          }
        });
    },

    openPerformance(id) {
      this.$router.push(`/performance?userid=${id}`);
    },
    isMobile() {
      if (window.innerWidth <= 760) {
        return true;
      } else {
        return false;
      }
    },
    refresh() {
      this.setPasswordSwitch = false;
      this.isMobile();
      this.pagination();
    },
    clearSearch() {
      this.search = "";
    },
    teamSelectionField(ids) {
      //handle selection of teamsId
      if (this.editedItem.role == "Teammanager") {
        return (this.editedItem.teamsId = ids);
      } else {
        if (ids.length > 0) {
          return (this.editedItem.teamsId = _.without(
            _.map(this.teams, (t) => {
              var found = _.find(ids, (f) => {
                return f == t.id;
              });
              if (found) {
                return (t.disabled = false);
              } else {
                return (t.disabled = true);
              }
            }),
            undefined
          ));
        } else {
          return (this.editedItem.teamsId = ids);
        }
      }
    },
    getImage(src) {
      return `${this.$store.state.ENDPOINT}/storages/get/${src}?access_token=${this.$store.getters.user.token}`;
    },
    accountImg() {
      if (this.logourl != "") {
        return this.logourl;
      } else {
        return require("../assets/account.png");
      }
    },
    async buttonOption(option) {
      if (option == "edit") {
        this.$refs.file.$refs.input.click();
      } else if (option == "delete") {
        if (this.editedItem.picture) {
          try {
            await this.$http.delete(
              `${this.$store.state.ENDPOINT}/attachments/${this.editedItem.pictureId}`
            );

            var jsonObject = {};

            jsonObject.pictureId = "";
            jsonObject.picture = "";

            this.$http
              .patch(
                `${this.ENDPOINT}/accounts/${this.editedItem.id}`,
                jsonObject
              )
              .then((res) => {
                this.$swal({
                  type: "success",
                  text: "Picture Removed",
                });
                this.logourl = "";
                this.refresh();
              });
          } catch (err) {
            console.log(err);
            if (err.body.error && err.body.error.message) {
              return this.$swal({
                type: "error",
                text: err.body.error.message,
              });
            }
            this.$swal({ type: "error", text: "Something went wrong" });
          }
        }
      }
    },

    openAssignMember(id) {
      this.$refs.assignMember.selectedAssignMember("Users", id);
    },
    /** recieve teams array of ids */
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }
      teams = _.without(teams, "allTeams");
      return teams;
    },

    /**PAGINATION */
    nextSet() {
      this.page++;
      this.pagination();
    },
    prevSet() {
      this.page--;
      this.pagination();
    },
    async pagination() {
      try {
        if (this.page > this.pageCount) {
          this.page = 1; //set default
        }
        this.allUsers = await this.getUserDetails(
          this.records,
          this.records * this.page - this.records
        );
        await this.getTotalAccountCount();
      } catch (e) {
        console.log(e);
        this.loader = false;
      }
    },
    // get total accounts by Teams
    getTotalAccountCount() {
      var teamIds = this.getTeamIds();
      if (this.search == undefined || this.search == null) {
        this.search = "";
      }
      this.$http
        .post(`${this.$store.state.ENDPOINT}/accounts/fetchCount`, {
          teams: teamIds,
          search: this.search,
        })
        .then((response) => {
          this.totalRecords = response.body;
          this.pageCount = Math.ceil(this.totalRecords / this.records);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // Fetch lists
    async fetchLists() {
      try {
        this.lists = [];
        // Teams sections
        var teamIds = this.getTeamIds();

        var res = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = res.body;

        if (this.lists.length < 1) {
          return;
        }
        if (
          this.$store.getters.user.role == "Manager" ||
          this.$store.getters.user.role == "Teammanager"
        ) {
          this.lists.push({
            id: "allLists",
            name: "All Lists",
          });
        }

        if (
          _.filter(this.lists, (d) => {
            return this.selectedList.toString() === d.id.toString();
          }).length < 1
        ) {
          this.selectedList == "";
          this.selectedList = this.lists.length > 0 ? this.lists[0].id : "";
        }
      } catch (e) {
        this.loader = false;
        console.log("error in list fetch", e);
      }
    },

    // get all the users by teamsid
    getUserDetails(limit, skip) {
      const self = this;
      this.loader = true;
      this.loadingText = "Loading... Please wait";
      // Teams sections
      var teamIds = this.getTeamIds();

      if (limit == undefined || skip == undefined) {
        limit = 6;
        skip = 0;
      }

      if (this.search == undefined || this.search == null) {
        this.search = "";
      }

      this.$http
        .post(`${this.$store.state.ENDPOINT}/accounts/fetch`, {
          teams: teamIds,
          search: this.search,
          limit: limit.toString(),
          skip: skip.toString(),
        })
        .then(async (response) => {
          self.allUsers = response.body;

          _.forEach(self.allUsers, (user) => {
            if (user.permissions.enableSessionLock === "false") {
              user.permissions.enableSessionLock = false;
            }
          });

          for (var x in self.allUsers) {
            var listNameAssign = [];

            for (var y in self.allUsers[x].lists) {
              listNameAssign.push(self.allUsers[x].lists[y].name);
            }

            self.allUsers[x].listName = listNameAssign;
          }

          for (var z in self.allUsers) {
            var teamNameAssign = [];
            for (var a in self.allUsers[z].teams) {
              teamNameAssign.push(self.allUsers[z].teams[a].name);
            }

            self.allUsers[z].teamName = teamNameAssign;
          }
          this.loader = false;
          this.loadingText = "";
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          // this.loadingText = "Error while fetching the users";
          this.loader = false;
          //error
        });
    },
    addTeam() {
      this.newMember = true;
      this.editedIndex = -1;
      this.editedItem = {
        name: "",
        email: "",
        phone: "",
        password: "",
        userid: "",
        permissions: {
          crm: false,
          modifyUser: false,
          canSkipCall: false,
          canAccessList: true,
        },
        role: "Submanager",
      };
      this.logourl = "";
      this.teamSelectionField([]);
      this.teamsId = [];
      this.teamsId = this.$store.getters.selectedTeam; // push the default team
      this.dialog = true;
    },
    editTeam(item) {
      this.editedIndex = this.allUsers.indexOf(item);
      this.editedItem = Object.assign(item);
      this.logourl =
        typeof item.picture != "undefined" && item.picture != ""
          ? this.getImage(item.picture)
          : "";
      this.editedItem.userid = item.username;
      if (item.role == "Teammanager") {
        this.teamsIds = item.teamsId;
      } else {
        this.teamsId = item.teamsId.length > 0 ? item.teamsId[0] : "";
      }
      // this.teamsIds = this.editedItem.teamsId;
      this.newMember = false;
      this.dialog = true;
      this.$nextTick();
    },
    upload(ev) {
      this.headerList = []; // init headerList as empty array
      this.importedData = []; // init the data as empty array
      // init the file after load
      var file = "";
      file = ev;

      var allowedFormats = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "text/csv",
        "text/comma-separated-values",
      ];

      if (!allowedFormats.includes(file.type)) {
        this.$f7.dialog.alert("Please select a csv or excel file");
        return;
      }

      this.fileName = ev.name;

      const reader = new FileReader();
      const self = this;

      reader.onload = function(eve) {
        var data = XLSX.read(eve.target.result, { type: "binary" });

        var XL_row_object = [];

        var sheetName = data.SheetNames[0];

        XL_row_object = XLSX.utils.sheet_to_json(data.Sheets[sheetName], {
          raw: false,
          header: 1,
          blankrows: false,
        });
        // define headerList
        self.headerList = XL_row_object[0];
        self.headerList.push("None");
        self.headerList = _.filter(self.headerList, (d) => {
          if (d != "") return d;
        });

        for (var y in XL_row_object) {
          var payload = {};

          if (y == 0) {
            continue;
          }

          for (var x in self.headerList) {
            payload[self.headerList[x]] = XL_row_object[y][x];
          }
          self.importedData.push(payload);
        }
      };
      reader.readAsBinaryString(file);
    },
    exportMembers() {
      var self = this;
      self.loader = true;

      if (self.allUsers.length < 1) {
        this.$swal({ type: "warning", text: "No records to export" });
        this.loader = false;
        return;
      }

      var data = this.allUsers;

      data = _.map(data, (d) => {
        var y = {
          Name: d.name,
        };

        if (d.username != "") {
          y.Username = d.username;
        } else {
          y.Username = "";
        }

        if (d.phone != "") {
          y.Phone = d.phone.toString();
        } else {
          y.Phone = "";
        }

        if (d.email != "") {
          y.Email = d.email;
        } else {
          y.Email = "";
        }

        if (d.role != "") {
          y.Role = d.role;
        } else {
          y.Role = "";
        }

        if (
          typeof d.permissions != "undefined" ||
          typeof d.permissions.crm != "undefined"
        ) {
          y.CRMAccess = d.permissions.crm;
        } else {
          y.CRMAccess = false;
        }

        if (
          typeof d.permissions != "undefined" ||
          typeof d.permissions.canSkipCall != "undefined"
        ) {
          y.SkipCall = d.permissions.canSkipCall;
        } else {
          y.SkipCall = false;
        }

        if (
          typeof d.permissions != "undefined" ||
          typeof d.permissions.modifyUser != "undefined"
        ) {
          y.ModifyUser = d.permissions.modifyUser;
        } else {
          y.ModifyUser = false;
        }

        if (typeof d.listName != "undefined") {
          y.AssignList = d.listName;
        } else {
          y.AssignList = d.listName;
        }

        if (typeof d.teamName != "undefined") {
          y.Teams = d.teamName;
        } else {
          y.Teams = "";
        }
        return y;
      });

      var csv = Papa.unparse(data);
      var csvData = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
      });

      var csvURL = null;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, "download.csv");
      } else {
        csvURL = window.URL.createObjectURL(csvData);
      }
      var tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "download.csv");
      tempLink.click();

      self.loader = false;
    },
    // bulk import contacts
    importAgents() {
      const self = this;
      // initiate loader
      self.loader = true;

      // validate
      if (self.$store.getters.user.role.toLowerCase() != "manager") {
        self.loader = false;
        return self.$swal({
          type: "warning",
          text: "Sorry, only managers are allowed to import team members.",
        });
      }

      if (
        self.importedItem.name == "" ||
        self.importedItem.phone == "" ||
        self.importedItem.username == "" ||
        self.importedItem.role == ""
      ) {
        self.loader = false;
        return self.$swal({
          type: "warning",
          text: "Name/Phone/Username/Role cannot be blank",
        });
      } else if (
        self.importedItem.name == "None" ||
        self.importedItem.phone == "None" ||
        self.importedItem.username == "None" ||
        self.importedItem.role == "None"
      ) {
        self.loader = false;
        return self.$swal({
          type: "warning",
          text: "Name/Phone/Username/Role selection should not be 'None'",
        });
      }

      // if the list provided by the user contains team name
      if (self.containsTeam) {
        if (self.importedItem.team == "") {
          return self.$swal({
            type: "warning",
            text: "Team selection cannot be blank",
          });
        }

        if (self.importedItem.team == "None") {
          return self.$swal({
            type: "warning",
            text: "Team selection should not be 'None'",
          });
        }
      }

      var payloadAgents = [];
      self.response = {};

      for (var x in self.importedData) {
        var payload = {};

        try {
          if (
            self.importedData[x][self.importedItem.name] == "" ||
            self.importedData[x][self.importedItem.phone] == ""
          ) {
            self.response[self.importedData[x][self.importedItem.username]] = {
              success: false,
              msg: "Phone/Name is not provided",
            };
            continue;
          }
          console.log("x==============", self.importedData[x]);

          payload.name =
            self.importedData[x][self.importedItem.name] == undefined
              ? ""
              : self.importedData[x][self.importedItem.name];

          var phoneNumber = validator.phoneNumberValidator(
            self.importedData[x][self.importedItem.phone]
          );

          // Email validation
          payload.email =
            self.importedData[x][self.importedItem.email] == undefined
              ? ""
              : self.importedData[x][self.importedItem.email];
          if (
            self.importedItem.email == "None" ||
            self.importedItem.email == ""
          ) {
            payload.email = "";
          }

          //daily Target validation
          /*payload.dTarget =
            (self.importedData[x][self.importedItem.dTarget] == undefined ||
            parseInt(self.importedData[x][self.importedItem.dTarget]) == "NaN")
              ? 0
              : parseInt(self.importedData[x][self.importedItem.dTarget]);
          if (
            self.importedItem.dTarget == "None" ||
            self.importedItem.dTarget == ""
          ) {
            payload.dTarget = 0;
          }*/

          //Monthly Target validation
          /*payload.mTarget =
            (self.importedData[x][self.importedItem.mTarget] == undefined ||
            parseInt(self.importedData[x][self.importedItem.mTarget]) == "NaN")
              ? 0
              : parseInt(self.importedData[x][self.importedItem.mTarget]);
          if (
            self.importedItem.mTarget == "None" ||
            self.importedItem.mTarget == ""
          ) {
            payload.mTarget = 0;
          }*/

          if (
            !/^\w+(\.\w+)?$/.test(
              self.importedData[x][self.importedItem.username]
            )
          ) {
            self.response[self.importedData[x][self.importedItem.username]] = {
              success: false,
              msg: "Invalid Username",
            };
            continue;
          }

          if (
            !/^(?!.* )(?=.*[a-zA-Z]).(?=.*[0-9]).{7,}$/.test(
              self.importedData[x][self.importedItem.password]
            )
          ) {
            payload.setPasswordFlag = false;
            payload.password = "abcdefgh1";
            self.response[self.importedData[x][self.importedItem.username]] = {
              success: true,
              msg: "Please set your Password from mail.",
            };
          }

          if (self.importedData[x][self.importedItem.username] == undefined) {
            self.response[self.importedData[x][self.importedItem.username]] = {
              success: false,
              msg: "'username' is not readable",
            };
            continue;
          }

          if (self.importedData[x][self.importedItem.username] == "") {
            self.response[self.importedData[x][self.importedItem.username]] = {
              success: false,
              msg: "'username' cannot be blank",
            };
            continue;
          }

          payload.username = self.importedData[x][
            self.importedItem.username
          ].toLowerCase();

          if (self.importedData[x][self.importedItem.password]) {
            payload.setPasswordFlag = true;
            payload.password = self.importedData[x][self.importedItem.password];
          }
          // Role validations
          if (self.importedData[x][self.importedItem.role] == undefined) {
            self.response[self.importedData[x][self.importedItem.username]] = {
              success: false,
              msg: "Invalid Role",
            };
            continue;
          }

          if (
            self.importedData[x][self.importedItem.role]
              .toLowerCase()
              .trim()
              .replace(/ /g, "")
              .toString() == "submanager" ||
            self.importedData[x][self.importedItem.role]
              .toLowerCase()
              .toString() == "agent" ||
            self.importedData[x][self.importedItem.role]
              .toLowerCase()
              .trim()
              .replace(/ /g, "")
              .toString() == "teammanager"
          ) {
            var constructedRole = self.importedData[x][self.importedItem.role]
              .toLowerCase()
              .trim()
              .replace(/ /g, "")
              .toString();

            payload.role =
              constructedRole.charAt(0).toUpperCase() +
              constructedRole.slice(1); //store the role as 'Agent' or 'Submanager'
          } else {
            self.response[self.importedData[x][self.importedItem.username]] = {
              success: false,
              msg: "Role should be 'Submanager'/'Agent'/'Teammanager'",
            };
            continue;
          }

          //if list contains team name then perform this
          //or put the selected team id as default
          if (self.containsTeam) {
            if (self.importedData[x][self.importedItem.team] == undefined) {
              self.response[
                self.importedData[x][self.importedItem.username]
              ] = {
                success: false,
                msg: "Team is not readable",
              };
              continue;
            }
            try {
              var val = self.importedData[x][self.importedItem.team];

              val = _.map(
                val
                  .trim()
                  .replace(/ /g, "")
                  .split(","),
                (v) => {
                  return v.toLowerCase();
                }
              );
              if (payload.role != "Teammanager" && val.length > 1) {
                self.response[
                  self.importedData[x][self.importedItem.username]
                ] = {
                  success: false,
                  msg: "Only Team Managers can be assigned to multiple teams",
                };
                continue;
              }

              if (val.length > 0) {
                val = _.without(
                  _.map(val, (d) => {
                    var found = _.find(self.teams, (t) => {
                      return (
                        d ==
                        t.name
                          .trim()
                          .replace(/ /g, "")
                          .toLowerCase()
                      );
                    });
                    if (found) {
                      return found.id.toString();
                    }
                  }),
                  undefined
                );
              }
              if (val.length < 1) {
                // (if) dont want to stop the process
                val.push(self.selectedTeam.toString());
              }

              payload.teamsId = val;
            } catch (e) {
              console.log(e);
            }
          } else {
            payload.teamsId = [];
            payload.teamsId.push(self.selectedTeam.toString()); // put the selected team id
          }

          payload.phone = phoneNumber;
          payload.companyId = self.user.companyId;

          // Default Dialer to Phone Dialer
          // payload.defaultDialer = "Phone Dialer";

          /** Permissions */
          payload.permissions = {};

          // Crm Access
          payload.permissions.crm = false;
          if (self.importedData[x][self.importedItem.crm] != undefined) {
            if (
              self.importedData[x][self.importedItem.crm]
                .toString()
                .toLowerCase()
                .trim() == "yes" ||
              self.importedData[x][self.importedItem.crm]
                .toString()
                .toLowerCase()
                .trim() == "true"
            ) {
              payload.permissions.crm = true;
            }
          }

          // Modify Users
          payload.permissions.modifyUser = false;
          if (self.importedData[x][self.importedItem.modifyUser] != undefined) {
            if (
              self.importedData[x][self.importedItem.modifyUser]
                .toString()
                .toLowerCase()
                .trim() == "yes" ||
              self.importedData[x][self.importedItem.modifyUser]
                .toString()
                .toLowerCase()
                .trim() == "true"
            ) {
              payload.permissions.modifyUser = true;
            }
          }

          payload.permissions.canSkipCall = false;
          if (
            self.importedData[x][self.importedItem.canSkipCall] != undefined
          ) {
            if (
              self.importedData[x][self.importedItem.canSkipCall]
                .toString()
                .toLowerCase()
                .trim() == "yes" ||
              self.importedData[x][self.importedItem.canSkipCall]
                .toString()
                .toLowerCase()
                .trim() == "true"
            ) {
              payload.permissions.canSkipCall = true;
            }
          }

          // // Modify Contact
          // payload.permissions.modifyContact = false;
          // if (
          //   self.importedData[x][self.importedItem.modifyContact] != undefined
          // ) {
          //   if (
          //     self.importedData[x][self.importedItem.modifyContact]
          //       .toString()
          //       .toLowerCase()
          //       .trim() == "yes" ||
          //     self.importedData[x][self.importedItem.modifyContact]
          //       .toString()
          //       .toLowerCase()
          //       .trim() == "true"
          //   ) {
          //     payload.permissions.modifyContact = true;
          //   }
          // }

          payloadAgents.push(payload);
        } catch (e) {
          console.log(e);
        }
      }

      // if there is no contacts to import then return
      if (payloadAgents.length < 1) {
        self.close();
        // function call
        self.refresh();
        if (Object.keys(self.response).length > 0) {
          self.resultDialog = true;
        }
        return;
      }
      payloadAgents = _.without(payloadAgents, undefined);
      self.$http
        .post(`${self.ENDPOINT}/accounts/bulk`, {
          accounts: payloadAgents,
          selected: self.importedItem,
        })
        .then((response) => {
          for (var x in response.body) {
            self.response[x] = {
              success: false,
              msg: response.body[x].msg,
            };
          }
          self.close();

          if (Object.keys(self.response).length > 0) {
            self.resultDialog = true;
          } else {
            return self.$swal({ type: "success", text: "Successful" });
          }
          // function call
          self.refresh();
          try {
            fb.log("import_agent");
          } catch (e) {
            console.log("Error in FB log", e);
          }
        })
        .catch((err) => {
          self.close();
        });
    },
    // Push & Pop of seleted account Ids using checkbox
    setSelectIds(e, acc) {
      if (e == true) {
        this.selectedIds.push(acc);
      } else {
        this.selectedIds = this.selectedIds.filter((a) => {
          return a.id != acc.id;
        });
      }
    },
    batchDelete() {
      var self = this;

      if (self.selectedIds.length < 1) {
        self.$swal({
          type: "warning",
          text: "Please select more than one member to delete",
        });
        return;
      }

      self
        .$swal({
          text: `Are you sure you want to delete Members?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "info",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            self.loader = true;
            self.$http
              .post(`${self.$store.state.ENDPOINT}/accounts/bulk/delete`, {
                accounts: self.selectedIds,
              })
              .then((response) => {
                self.loader = false;
                self.selectedIds = [];
                self.refresh();
                self.$swal({ type: "success", text: "Successfully Deleted" });
                fb.log("batch_agent_delete");
              })
              .catch((e) => {
                console.log(e);
                self.loader = false;
                self.$swal({ type: "error", text: e.body.error.message });
              });
          }
        })
        .catch((err) => {
          self.loader = false;
          self.$swal({ type: "error", text: err.body.error.message });
        });
    },

    resetPassword(e) {
      var self = this;

      if (self.newPassword == "") {
        self.$swal({
          type: "error",
          text: "Please provide a password",
        });
        return;
      }

      if (!/^(?!.* )(?=.*[a-zA-Z]).(?=.*[0-9]).{7,}$/.test(self.newPassword)) {
        //  this.loader = false
        return this.$swal({
          type: "warning",
          text:
            "Password should have minimum eight characters, at least one letter and one number",
        });
      }

      self.loaderDialog = true;

      self.$http
        .put(`${this.ENDPOINT}/accounts/updatePassword`, {
          userid: self.resetuserData.id,
          newPassword: self.newPassword,
        })
        .then((response) => {
          try {
            fb.log("update_password");
          } catch (e) {
            console.log("Error in FB log", e);
          }
          self.loaderDialog = false;
          self.dialogResetPassword = false;
          self
            .$swal({
              text: "Password change successfully",
              type: "success",
              confirmButtonColor: "info",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.value) {
                if (self.user.id == self.resetuserData.id) {
                  self.logout();
                }
              }

              try {
                fb.log("password_change");
              } catch (e) {
                console.log("Error in FB log", e);
              }
            });
        })
        .catch((err) => {
          self.loaderDialog = false;
          self.$swal({ type: "error", text: err.body.error.message });
        });
    },

    changePassword(user) {
      var self = this;
      self.resetuserData = "";
      self.newPassword = "";
      self.dialogResetPassword = true;
      self.resetuserData = user;
      self.dialog = false;
    },

    // reset data after operation
    close() {
      const self = this;
      self.dialog = false;
      self.importDialog = false;
      // clear add member options
      self.editedItem = {
        name: "",
        email: "",
        phone: "",
        password: "",
        userid: "",
        permissions: {
          crm: false,
          modifyUser: false,
          canSkipCall: false,
          canAccessList: true,
        },
        role: "Agent",
        teamsId: [],
      };

      self.importedItem = {
        name: "",
        email: "",
        phone: "",
        role: "",
        username: "",
        password: "",
        crm: "",
        modifyUser: "",
        // modifyContact: "",
      };
      // close loader
      self.loader = false;
    },

    // get response status
    status(type, text) {
      switch (type) {
        case "error":
          this.status.icon = "error";
          break;
        case "success":
          this.status.icon = "success";
          break;
        case "warning":
          this.status.icon = "error";
          break;
      }
      this.status.text = text;
      this.status.type = type;
    },

    // Get the number with country code
    getPhoneNumber(e, phone) {
      try {
        if (e) {
          return (this.editedItem.phone = phone.number.e164);
        } else {
          return (this.editedItem.phone = "");
        }
      } catch (e) {
        // error
      }
    },

    allowRoleSelect() {
      if (this.editedIndex > -1) {
        if (this.editedItem.role == "Manager") return true;
        if (
          this.editedItem.role == "Teammanager" &&
          this.$store.getters.user.role == "Submanager"
        )
          return true;
      }
      return false;
    },

    // Add member
    async save() {
      const self = this;

      //teams id management
      var teams = [];
      if (this.editedItem.role == "Teammanager") {
        teams = this.teamsIds;
      } else {
        if (this.teamsId != "") teams = [this.teamsId];
      }

      if (teams.length < 1 || this.teamsId == "allTeams") {
        return this.$swal({
          type: "warning",
          text: "Team selection cannot be blank",
        });
      }

      // Loader Start
      this.loader = true;

      // Check
      if (this.editedItem.name == "") {
        this.loader = false;
        return this.$swal({ type: "warning", text: "Name is blank" });
      }

      if (this.editedItem.userid == "") {
        this.loader = false;
        return this.$swal({
          type: "warning",
          text: "Username is Blank",
        });
      }

      var username = this.editedItem.userid.toLowerCase();

      if (this.editedItem.email != "" && this.editedItem.email != "") {
        if (
          !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(this.editedItem.email)
        ) {
          this.loader = false;
          return this.$swal({ type: "warning", text: "Email Id is invalid" });
        }
      }

      var jsonObject = {
        name: this.editedItem.name,
        email: this.editedItem.email,
        phone: this.editedItem.phone,
        role: this.editedItem.role,
        setPasswordFlag: this.setPasswordSwitch,
        teamsId: teams,
        permissions: {
          crm: this.editedItem.permissions.crm,
          modifyUser: this.editedItem.permissions.modifyUser,
          canSkipCall: this.editedItem.permissions.canSkipCall,
          canRecord: this.editedItem.permissions.canRecord,
          canAccessList: this.editedItem.permissions.canAccessList,
          recordCallsWithTrueCaller: this.editedItem.permissions
            .recordCallsWithTrueCaller,
          canAccessOnlyMyContacts: this.editedItem.permissions
            .canAccessOnlyMyContacts,
          canAccessCallLog: this.editedItem.permissions.canAccessCallLog,
          whatsapp: this.editedItem.permissions.whatsapp,
          enableSessionLock: this.editedItem.permissions.enableSessionLock,
          canMakeAttendance: this.editedItem.permissions.canMakeAttendance,
          canCaptureLocation: this.editedItem.permissions.canCaptureLocation,
          canCapturePhoto: this.editedItem.permissions.canCapturePhoto,
        },
        sessionStart: this.editedItem.sessionStart,
        sessionEnd: this.editedItem.sessionEnd,
        /*dTarget: parseInt(this.editedItem.dTarget),
        mTarget: parseInt(this.editedItem.mTarget)*/
      };

      // Check Edit Or New
      if (this.editedIndex > -1) {
        // Patch - Update A User

        try {
          var response = await self.$http.patch(
            `${self.ENDPOINT}/accounts/${self.editedItem.id}`,
            jsonObject
          );
          if (response) {
            try {
              fb.log("update_user");
            } catch (e) {
              console.log("Error in FB log", e);
            }
            self.loader = false;
            self.$swal({ type: "success", text: "Successful" });
            self.close();
            self.refresh();
          }
        } catch (e) {
          //error
          self.loader = false;
          if (e.body.error.message.includes("Email already exists")) {
            this.editedItem.email = "";
          }
          self.$swal({
            type: "error",
            text: "Something went wrong updating this account",
          });
        }
      } else {
        // Post - Create A New User

        if (!this.setPasswordSwitch && this.editedItem.email == "") {
          this.loader = false;
          return this.$swal({
            type: "warning",
            text: "Please enter an email id for password set.",
          });
        }

        if (
          this.editedItem.role != "Agent" &&
          this.editedItem.role != "Submanager" &&
          this.editedItem.role != "Teammanager"
        ) {
          this.loader = false;
          return this.$swal({
            type: "warning",
            text: "User Role is not selected",
          });
        }

        if (!this.setPasswordSwitch) {
          this.editedItem.password = "abcdefg1";
        }

        // Patch
        jsonObject.username = username;
        jsonObject.password = this.editedItem.password;
        jsonObject.companyId = this.user.companyId;

        console.log("jsonObject", jsonObject);

        try {
          var res = await self.$http.post(
            `${self.ENDPOINT}/accounts`,
            jsonObject
          );
          if (res) {
            setTimeout(() => {
              self.$swal({ type: "success", text: "Successful" });
              self.close();
              self.loader = false;
              self.refresh();
            }, 5000);
            fb.log("add_user");
          }
        } catch (e) {
          self.loader = false;
          console.log(e);
          if (e.body.error.message.includes("User already exists")) {
            self.$swal({
              type: "error",
              text:
                "Username / Email is already in use, kindly use another Username/Email, for example john_yourcompany@example.com",
            });
          } else if (e.body.error.message.includes("Email already exists")) {
            self.$swal({
              type: "error",
              text:
                "Email address is already in use, you can recover your password in case you have already created the account.",
            });
          } else {
            self.$swal({ type: "error", text: e.body.error.message });
          }
        }
      }
    },

    logout() {
      var self = this;
      self.$store.dispatch("setUser", false);
      window.localStorage.clear();
      window.location.href = "/";
    },

    // Delete User
    deleteUser(user) {
      var self = this;

      this.$swal({
        text: `Are you sure you want to delete ${user.username}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "info",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.loader = true;
          var manager = [];

          for (var x in self.allUsers) {
            if (self.allUsers[x].role == "Manager")
              manager.push(self.allUsers[x]);
          }

          if (manager.length <= 1 && user.role == "Manager") {
            return self.$swal({
              type: "warning",
              text: "Last manager cannot be deleted",
            });
          }

          this.$http
            .delete(`${this.ENDPOINT}/accounts/${user.id}`, {
              userId: user.id,
              companyId: user.companyId,
            })
            .then((response) => {
              this.refresh();
              this.loader = false;

              this.$swal({
                type: "success",
                text: `${user.username} is deleted successfully`,
              });
              this.close();
              // function call
              this.getUserDetails();
              fb.log("delete_user");
            })
            .catch((err) => {
              this.loader = false;
              this.$swal({ type: "error", text: err.body.error.message });
            });
        }
      });
    },
    saveTeam() {
      if (this.teamItem.name == "") {
        return this.$swal({ type: "warning", text: "Blank Team Name" });
      }

      if (this.editedIndex > -1) {
        this.$http
          .put(`${this.ENDPOINT}/teams/${this.teamItem.id}`, {
            name: this.teamItem.name,
            desc: this.teamItem.desc,
            companyId: this.user.companyId,
          })
          .then((response) => {
            fb.log("team_update");
            // this.fetchTeams();
            this.$store.dispatch("getTeams", this.$store.getters.user);
            this.$swal({
              type: "success",
              text: `${this.teamItem.name} updated successfully`,
            });
            this.teamItem = {
              name: "",
              desc: "",
            };
            this.addTeamDialog = false;
          })
          .catch((e) => {
            console.log(e);
            this.addTeamDialog = true;
            this.$swal({
              type: "error",
              text: "Something went wrong while editing team",
            });
          });
      } else {
        this.teamItem.companyId = this.user.companyId;

        this.$http
          .post(`${this.ENDPOINT}/teams/add`, { data: this.teamItem })
          .then((response) => {
            fb.log("team_add");
            // this.fetchTeams();
            this.$store.dispatch("getTeams", this.$store.getters.user);
            this.$swal({
              type: "success",
              text: `${this.teamItem.name} created successfully`,
            });
            this.teamItem = {
              name: "",
              desc: "",
            };
            this.addTeamDialog = false;
          })
          .catch((e) => {
            console.log(e);
            this.addTeamDialog = true;
            this.$swal({
              type: "error",
              text: "Something went wrong while creating team",
            });
          });
      }
    },

    editItem(item) {
      this.editedIndex = this.teams.indexOf(item);
      this.teamItem = Object.assign(item);

      this.addTeamDialog = true;
    },

    openDialog() {
      this.editedIndex = -1;
      this.teamItem = {
        name: "",
        desc: "",
      };

      this.addTeamDialog = true;
    },

    delItem(item) {
      this.$swal
        .fire({
          text: `Whole team (${item.name}) will be deleted. Continue ?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            this.loader = true;
            this.$http.delete(`${this.ENDPOINT}/teams/${item.id}`).then(
              (response) => {
                try {
                  fb.log("team_deleted");
                } catch (e) {
                  console.log("Error in FB log", e);
                }
                this.loader = false;
                this.$swal({
                  type: "success",
                  text: `${item.name} deleted successfully`,
                });
                // this.fetchTeams();
                this.$store.dispatch("getTeams", this.$store.getters.user);
              },
              (error) => {
                this.loader = false;
                this.$swal({
                  type: "error",
                  text: error.body.error.message,
                });
              }
            );
          }
        });
    },
    onFileSelect(file) {
      this.mime_type = file.type;
      this.fileName = file.name;
      if (typeof FileReader === "function") {
        this.cropDialog = true;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    saveImage() {
      const self = this;
      const compressor = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            compress(event.target.result, {
              width: 512,
              type: "image/png", // default
              max: 2048, // max size
              min: 2, // min size
              quality: 0.5,
            })
              .then((result) => {
                resolve(result);
              })
              .catch((e) => {
                console.log("err", e);
              });
          };
          reader.onerror = (error) => reject(error);
        });

      function srcToFile(src, fileName, mimeType) {
        return fetch(src)
          .then(function(res) {
            return res.arrayBuffer();
          })
          .then(function(buf) {
            return new File([buf], fileName, { type: mimeType });
          });
      }

      var uncompressed = self.$refs.cropper.getCroppedCanvas().toDataURL();

      srcToFile(uncompressed, self.fileName, "image/png").then(async (file) => {
        var compressed = await compressor(file);
        self.logourl = compressed;
        srcToFile(self.logourl, self.fileName, "image/png").then(
          async (file) => {
            var formData = new FormData();
            formData.append("file", file);
            formData.append("type", "picture");
            formData.append("companyId", self.$store.state.companyDetails.id);

            var config = {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: self.$store.state.user.token,
              },
            };
            self.loader = true;

            //delete previous logo
            if (self.editedItem.picture) {
              await self.$http.delete(
                `${self.$store.state.ENDPOINT}/attachments/${self.editedItem.pictureId}`
              );
            }

            //store the file to storage
            var attachment = await self.$http.post(
              `${self.$store.state.ENDPOINT}/attachments/upload`,
              formData,
              config
            );
            attachment = attachment.body;
            var jsonObject = {};
            jsonObject.pictureId = attachment.id;
            jsonObject.picture = attachment.name;

            self.$http
              .patch(
                `${self.ENDPOINT}/accounts/${this.editedItem.id}`,
                jsonObject
              )
              .then((response) => {
                setTimeout(() => {
                  self.$swal({ type: "success", text: "Picture Updated" });
                  // function call
                  self.loader = false;
                  self.refresh();
                }, 5000);
              })
              .catch((err) => {
                self.loader = false;
                //error
                self.$swal({
                  type: "error",
                  text: "Something went wrong updating this account",
                });
              });
          }
        );
      });
    },
  },
};
</script>

<style scoped>
.wrap-text-title {
  white-space: normal;
}

.v-divider {
  margin: 5px 0px 15px 0px;
}

.v-btn:not(.v-btn--round).v-size--small {
  left: -25px !important;
  text-transform: none;
}

.v-card {
  box-shadow: 0px 8px 60px -10px rgba(13, 28, 39, 0.3);
}

.dotted-gradient {
  background-image: linear-gradient(
    to right,
    #313131 40%,
    rgba(255, 255, 255, 0) 40%
  );
  background-position: bottom;
  background-size: 6px 2px;
  background-repeat: repeat-x;
  text-transform: inherit;
  padding-bottom: 2px;
}

.v-card {
  transition: opacity 0.4s ease-in-out;
}

/*
.v-card:not(.on-hover) {
  opacity: 0.6;
 } */

.show-btns {
  color: rgba(0, 0, 0, 1) !important;
}

.text-caption-small {
  font-size: 11px;
  line-height: 1.4;
}

.add_button {
  padding: 10px !important;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 7px;
}

.add_button:hover {
  padding: 10px !important;
  cursor: pointer;
  transition-delay: 0.1s;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 14px;
  box-shadow: 1px 3px 9px #c3c3c3 !important;
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  max-height: 5rem; /* Adjust based on the height of two lines of chips */
  transition: max-height 0.3s ease;
}

.chip-container .chip-item.chip-hidden {
  display: none;
}

.chip-container.expanded {
  max-height: none;
}

@media (min-width: 1200px) {
  .paddingCustomAddMember {
    padding-top: 95px;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .paddingCustomAddMember {
    padding-top: 95px;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .paddingCustomAddMember {
    padding-top: 20px;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .paddingCustomAddMember {
    padding-top: 7px;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .paddingCustomAddMember {
    padding-top: 7px;
  }
}

.customChipEllipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.customPaddingandRadius {
  padding: 10px;
  border-radius: 12px;
}
</style>
