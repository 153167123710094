<template>
  <v-dialog
    v-model="assignMemberDialog"
    width="650"
    persistent
  >
    <v-card>
      <v-card-title class="primarygrad">
        <span v-if="!hideListSelector">Assign List</span>
        <span v-else>Assign Member</span>
        <v-spacer></v-spacer>
        <v-tooltip
          v-if="hideListSelector"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              depressed
              v-bind="attrs"
              style="text-transform: none; font-weight: 400; font-size: 14px"
              v-on="on"
              @click="
                $router.push({
                  path: '/manage',
                  query: { topic: 'assignments' },
                })
              "
            >
              <v-icon
                size="22"
                style="margin-top: 3px"
              >mdi-eye-outline</v-icon>
            </v-btn>
          </template>
          <span>View all Assignments</span>
        </v-tooltip>
        <v-icon @click="assignMemberDialog = false">mdi-close-circle</v-icon>
      </v-card-title>
      <div v-if="!hideMemberSelector">
        <p
          v-if=" selectUsersForAssignment.length > 0"
          class="pl-5 pt-3 mb-0"
        >
          Members assigned:
        </p>
        <v-card-text
          v-if="selectUsersForAssignment.length > 0"
          class="d-flex flex-wrap py-3"
        >
          <v-chip
            v-for="(member, i) in selectUsersForAssignment"
            :key="member.id"
            label
            class="mr-2 mb-2"
            close
            @click:close="selectUsersForAssignment.splice(i, 1)"
          >
            <v-avatar
              color="primarygrad"
              left
            >
              <v-img
                v-if="member.picture"
                :src="getImage(member.picture)"
              />
              <span
                v-else
                class="font-weight-bold"
              >
                {{ member.name.substring(0, 1).toUpperCase() }}
              </span>
            </v-avatar>
            {{ member.name }}
          </v-chip>
        </v-card-text>
        <v-card-text
          v-else
          class="py-3"
        >No members assigned</v-card-text>
        <v-card-text class="mb-0 pb-0">
          <v-text-field
            ref="search"
            v-model="search"
            label="Search"
            placeholder="Search Member"
            prepend-inner-icon="mdi-magnify"
            single-line
            hide-details
            autofocus
            full-width
          />
          <v-divider />
          <v-list>
            <template v-for="member in searchedUser">
              <v-list-item
                v-if="!selectUsersForAssignment.includes(member)"
                :key="member.id"
                @click="selectUsersForAssignment.push(member)"
              >
                <v-list-item-avatar color="primarygrad">
                  <v-img
                    v-if="member.picture"
                    :src="getImage(member.picture)"
                  />
                  <span
                    v-else
                    class="font-weight-bold"
                  >
                    {{ member.name.substring(0, 1).toUpperCase() }}
                  </span>
                </v-list-item-avatar>
                <v-list-item-title v-text="member.name"></v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </div>

      <v-card-text style="padding: 20px">
        <v-flex
          v-if="!hideListSelector"
          xs12
          sm12
          md12
        >
          <v-select
            v-model="listSelectedForAssignment"
            :items="
              lists.filter((l) => {
                return l.id != 'allLists';
              })
            "
            item-text="name"
            item-value="id"
            label="Select List"
            outlined
            dense
            @change="getUserByListId($event)"
          ></v-select>
        </v-flex>
        <!-- <v-flex v-if="!hideMemberSelector">
          <v-autocomplete
            v-if="calcUsers && calcUsers.length > 0"
            v-model="selectUsersForAssignment"
            :items="calcUsers"
            :menu-props="{ top: true, offsetY: true }"
            filled
            chips
            color="blue-grey lighten-2"
            label="Select Member"
            item-text="name"
            item-value="id"
            multiple
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select;"
                @click:close="remove(data.item)"
              >
                <v-avatar color="primarygrad" left>
                  <v-img
                    v-if="data.item.picture"
                    :src="getImage(data.item.picture)"
                  />
                  <span v-else class="white--text">
                    {{ data.item.name.substring(0, 1).toUpperCase() }}
                  </span>
                </v-avatar>
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar color="primarygrad">
                  <v-img
                    v-if="data.item.picture"
                    :src="getImage(data.item.picture)"
                  />
                  <span v-else class="white--text headline">
                    {{ data.item.name.substring(0, 1).toUpperCase() }}
                  </span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.group"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-flex> -->

        <!--         
        <v-flex v-if="page != 'Users'" xs12 sm12 md12>
          <v-select
            v-model="selectUsersForAssignment"
            label="Select Users"
            :items="calcUsers"
            item-text="name"
            item-value="id"
            outlined
            chips
            multiple
          ></v-select>
        </v-flex> -->

        <!-- <v-row v-if="page != 'Users'">
          <v-flex xs6 sm6 md6>
            <p
              style="font-size: 13px;opacity: 0.7; margin-left: 15px; margin-top: 5px"
            >
              Can't find member?
            </p>
          </v-flex>
          <v-flex xs6 sm6 md6 style="justify-content: right; text-align: right">
            <v-chip
              @click="
                $router.replace({
                  path: '/users',
                  query: { dashboardReq: true },
                })
              "
            >
              <span> <v-icon left color="green">add</v-icon>Member </span>
            </v-chip>
          </v-flex>
        </v-row> -->
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="assignMemberDialog = false"
        >Close</v-btn>
        <v-btn
          depressed
          class="primarygrad"
          @click="assignMemberSave"
        >Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import fb from "../js/firebase";
export default {
  props: ["lists", "allUsers", "selectedList"],
  data() {
    return {
      search: "",
      hideListSelector: false,
      hideMemberSelector: false,
      listSelectedForAssignment: "",
      selectUsersForAssignment: [],
      assignMemberDialog: false,
      page: "",
      memberId: ""
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "selectedTeam"]),
    calcUsers() {
      var users = [];
      var list = _.find(this.lists, l => {
        return l.id == this.listSelectedForAssignment;
      });

      if (list == undefined) return [];

      var teamsId = list.teamsId;
      users = _.without(
        _.map(this.allUsers, u => {
          if (_.includes(u.teamsId, teamsId)) {
            return u;
          }
        }),
        undefined
      );
      return users;
    },
    searchedUser() {
      const search = this.search.toLowerCase();
      if (!search) return this.calcUsers;

      return this.calcUsers.filter(item => {
        const name = item.name.toLowerCase();
        return name.indexOf(search) > -1;
      });
    }
  },

  mounted() {},
  methods: {
    close() {
      this.$emit("closeData");
    },
    remove(item) {
      const temp = this.selectUsersForAssignment.filter(x => x.id != item.id);
      this.selectUsersForAssignment = temp;
    },
    getImage(src) {
      return `${this.$store.state.ENDPOINT}/storages/get/${src}?access_token=${this.$store.getters.user.token}`;
    },
    getUserByListId(id) {
      this.preloader = true;
      this.selectUsersForAssignment = [];
      if (typeof id === "undefined" || id == undefined || id == "") {
        return; //return if id doesnt exist
      }

      this.$http
        .get(`${this.ENDPOINT}/lists/${id}/accounts`)
        .then(response => {
          this.preloader = false;
          var ids = response.body.map(i => i.id);
          this.selectUsersForAssignment = this.calcUsers.filter(u =>
            ids.includes(u.id)
          );
        })
        .catch(e => {
          this.loader = false;
          this.preloader = false;
          this.$swal({ type: "error", text: "Error while getting accounts" });
        });
    },
    async assignMemberSave() {
      const self = this;
      if (
        self.selectUsersForAssignment[0] &&
        typeof self.selectUsersForAssignment[0] == "object"
      )
        self.selectUsersForAssignment = self.selectUsersForAssignment.map(
          c => c.id
        );

      // console.time("check");

      //this.loader = true
      // validate
      if (self.listSelectedForAssignment == "") {
        self.loader = false;
        return self.$swal({
          type: "warning",
          text: "Select a list before assigning members"
        });
      }

      if (
        self.page == "Users" &&
        !_.includes(self.selectUsersForAssignment, self.memberId)
      ) {
        // self.selectUsersForAssignment = _.map(
        //   self.selectUsersForAssignment,
        //   "id"
        // );
        self.selectUsersForAssignment.push(self.memberId);
      }

      await self.$http
        .post(
          `${self.ENDPOINT}/listsAccountThroughs/set-list-account-through`,
          {
            listsId: self.listSelectedForAssignment,
            accountIds: self.selectUsersForAssignment
          }
        )
        .then(response => {
          // console.log("Response", response);
          self.selectUsersForAssignment = [];
          fb.log("list_assign");
          self.$swal({
            type: "success",
            text:
              "Successfully assigned list, Now the member can dial this list from the app"
          });
          self.assignMemberDialog = false;
          self.close();
          self.refresh();
        })
        .catch(err => {
          self.close();
          self.refresh();
          console.log("Error", err);
        });

      // console.timeEnd("check");
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");
      return teams;
    },
    async selectedAssignMember(page, memberId) {
      if (page == "Users") {
        //This means we will show only lists
        var lists = _.filter(this.lists, l => {
          return l.id != "allLists";
        });

        this.listSelectedForAssignment = lists[0].id;
        this.hideMemberSelector = true;
        this.hideListSelector = false;
        this.memberId = memberId;
      } else if (page == "Lists") {
        this.listSelectedForAssignment = this.selectedList;
        this.hideListSelector = true;
        this.hideMemberSelector = false;
      }
      if (this.selectedTeam !== "allTeams") {
        await this.getUserByListId(this.listSelectedForAssignment);
        this.page = page;
        this.assignMemberDialog = true;
      } else {
        this.$swal({
          type: "error",
          text:
            "Please choose a single team from the team dropdown on the side bar"
        });
      }
    },
    refresh() {
      this.$emit("refreshAll");
    }
  }
};
</script>
